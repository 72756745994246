import { BlockMedia, BlockHighlightText, BlockResults } from './Blocks';
import BlockImmersiveVideo from './BlockImmersiveVideo';
import BlockVimeo from './BlockVimeo';
import BlockQuote from './BlockQuote';
import BlockTextAndMedia from './BlockTextAndMedia';
import BlockSideBySide from './BlockSideBySide';
import BlockSectionHeadline from './BlockSectionHeadline';

const Block = {
  ContentfulBlockMedia: BlockMedia,
  ContentfulBlockQuote: BlockQuote,
  ContentfulBlockHighlightText: BlockHighlightText,
  ContentfulBlockResults: BlockResults,
  ContentfulBlockVimeoEmbed: BlockVimeo,
  ContentfulBlockImmersiveVideo: BlockImmersiveVideo,
  ContentfulBlockTextAndMedia: BlockTextAndMedia,
  ContentfulBlockSideBySide: BlockSideBySide,
  ContentfulSectionHeadline: BlockSectionHeadline,
};
export default Block;
