import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { css } from '@oddcommon/utils';
import styles from './index.module.scss';

const BlockSideBySide = ({ title, items, layout, isLight, ...props }) => {
  const isInset = layout === 'inset';
  const renderItem = (item, index) => {
    if (item.file.contentType.includes('video')) {
      return (
        <video
          key={index}
          aria-describedby={title}
          src={item.file.url}
          // className={isInset ? styles['inset-video'] : styles['full-bleed-video']}
          loop
          muted
          preload="auto"
          autoPlay
          playsInline
          {...props}
          onLoadedMetadata={e => e.target.play()}
        />
      );
    } else if (item.file.contentType.includes('image')) {
      return (
        <GatsbyImage
          key={index}
          style={{ pointerEvents: 'none' }}
          className={styles.image}
          placeholder="blurred"
          image={getImage(item.image)}
          alt={title}
        />
      );
    } else {
      <div></div>;
    }
  };
  return (
    <section
      className={css(styles.sideBySide, isInset && styles.inset, isLight && styles.isLight)}
      {...props}
    >
      <div className={styles.content}>{items.map((item, index) => renderItem(item, index))}</div>
    </section>
  );
};
export default BlockSideBySide;
