import CaseStudyThumb from '@components/CaseStudyThumb';
import CTA from '@components/CTA';
import Link from '@components/Link';
import { formatClients } from '@js-utils/formatting';
import { css } from '@oddcommon/utils';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './SuggestedCaseStudy.module.scss';

const SuggestedCaseStudy = props => {
  const { path, clientList, projectTitle, primaryImage, thumbnailImage } = props;

  const [ref, inView] = useInView({ threshold: 0.25, triggerOnce: true });

  let clientName = formatClients(clientList);

  return (
    <div className={css(styles.suggestion, inView && styles.ready)}>
      <div ref={ref} className={styles.thumb}>
        <div className={styles.thumbContent}>
          <CaseStudyThumb
            styles={styles}
            content={primaryImage}
            thumbnailImage={thumbnailImage}
            alt={`${clientName} - ${projectTitle}`}
          />
        </div>
      </div>
      <div className={styles.overlay} />
      <div className={styles.copyWrapper}>
        <div className={styles.copy}>
          {clientName && <p className={styles.client}>{clientName}</p>}
          <Link to={path} className={styles.titleWrapper}>
            <h3 className={styles.title}>{projectTitle}</h3>
          </Link>
          <CTA to={path} className={styles.link}>
            View Next Case Study
          </CTA>
        </div>
      </div>
    </div>
  );
};

export default SuggestedCaseStudy;
