import React, { useRef } from 'react';

import styles from './AutoHeadline.module.scss';
import { css } from '@oddcommon/utils';

const AutoHeadline = props => {
  const { className, children, minWords, maxWords, ...otherProps } = props;

  let words = children.trim().split(' ');
  let lines = [];
  const splits = Math.floor(words.length / maxWords) - 1;
  for (let split = 0; split <= splits; split++) {
    if (split === splits) {
      if (words.length < minWords) {
        const tempWords = lines[split - 1].split(' ');
        words = [tempWords[tempWords.length - 1], ...words];
        lines[split - 1] = tempWords.slice(0, tempWords.length - 1).join(' ') + ' ';
      }
      lines[split] = words.join(' ');
      break;
    }
    lines[split] = words.slice(0, maxWords).join(' ') + ' ';
    words = words.slice(maxWords);
  }
  const headline = lines.map((line, index) => {
    if (line === '') {
      return;
    }
    return (
      <>
        {index === 0 ? '' : <br className={styles.autoBreak} />}
        <span key={line}>{line}</span>
      </>
    );
  });
  return (
    <h1 key={headline} className={css(className)} {...otherProps}>
      {headline}
    </h1>
  );
};

AutoHeadline.defaultProps = {
  minWords: 2,
  maxWords: 6,
};

export default AutoHeadline;
