import React from 'react';

import styles from './BlockQuote.module.scss';

const BlockQuote = props => {
  const {
    text: { text },
    citationAuthorName,
    citationAuthorRole,
    ...otherProps
  } = props;
  return (
    <section className={styles.section}>
      <blockquote className={styles.blockquote} {...otherProps}>
        <p>{text}</p>
        <cite>
          <p>
            <span>{citationAuthorName}</span>
            {citationAuthorRole}
          </p>
        </cite>
      </blockquote>
    </section>
  );
};

export default BlockQuote;
