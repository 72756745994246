import React, { useEffect, useRef } from 'react';

import { css } from '@oddcommon/utils';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';

import styles from './BlockTextAndMedia.module.scss';

const BlockTextAndMedia = props => {
  const { className, content, alignment, media, type, ...otherProps } = props;
  const alignmentStyles = alignment === 'Text Left' ? styles.textLeft : styles.textRight;
  const videoRef = useRef();

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && videoRef.current) {
      videoRef.current.play();
    }
  }, [inView]);
  const mediaContent =
    media.file.contentType === 'video/mp4' ? (
      <video ref={videoRef} className={styles.video} playsInline muted loop>
        <source type={media.file.contentType} src={media.file.url} />
      </video>
    ) : (
      <GatsbyImage className={styles.image} placeholder="blurred" image={getImage(media.image)} />
    );
  return (
    <section className={css(className, styles.section, alignmentStyles)} {...otherProps} ref={ref}>
      <div className={styles.content}>
        <div className={styles.media}>
          <div className={styles.mediaContent}>
            <>
              {type === 'Image & Mobile' ||
                (type === 'Video & Mobile' && (
                  <div className={styles.mediaWrapper}>
                    {mediaContent}

                    {/* <StaticImage
                      className={styles.frame}
                      src="../../../assets/images/phoneframe.png"
                      alt="Smartphone"
                    /> */}
                  </div>
                ))}
              {type === 'Image' && mediaContent}
            </>
          </div>
        </div>
        <div className={styles.copy}>
          <div>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockTextAndMedia;
