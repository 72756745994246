import React, { useRef, useState, useEffect, useCallback } from 'react';
import VimeoPlayer from '@vimeo/player';

import { css } from '@oddcommon/utils';

import styles from './BlockVimeo.module.scss';

const BlockVimeo = ({ title, vimeoId }) => {
  const [playing, setPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [percent, setPercent] = useState(0);
  const [playerBounds, setPlayerBounds] = useState({ width: 0, height: 0 });
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [loadFailed, setLoadFailed] = useState(false);

  const containerRef = useRef();
  const playerRef = useRef();

  const startScrubbing = () => {
    setIsScrubbing(true);
  };
  const stopScrubbing = () => {
    setIsScrubbing(false);
  };

  const playVideo = () => {
    if (playerRef.current) {
      setTimeout(() => {
        playerRef.current.play();
      }, 333); // The same timing as $speed
    }
  };
  const pauseVideo = () => {
    if (playerRef.current) playerRef.current.pause();
  };

  const toggleVideo = useCallback(() => {
    if (!playing) {
      playVideo();
    } else {
      pauseVideo();
    }
    setPlaying(!playing);
  }, [playing]);

  const handleScrub = useCallback(
    e => {
      if (videoDuration) {
        const newPercent = e.target.value / 100;
        playerRef.current.setCurrentTime(videoDuration * newPercent);
        setPercent(e.target.value);
      }
    },
    [videoDuration]
  );

  useEffect(() => {
    containerRef.current.blur();
  }, [playing]);

  useEffect(() => {
    if (!isScrubbing) {
      let newPercent = (currentTime / videoDuration) * 100;
      newPercent = isNaN(newPercent) ? 0 : newPercent;
      setPercent(newPercent);
    }
  }, [currentTime, videoDuration, isScrubbing, percent]);

  useEffect(() => {
    const container = containerRef.current;
    const handleResize = () => {
      const bounds = container.getBoundingClientRect();
      setPlayerBounds(bounds);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  useEffect(() => {
    playerRef.current = new VimeoPlayer(containerRef.current, {
      id: vimeoId,
      controls: false,
      playsInline: true,
    });

    playerRef.current.setVolume(1).catch(error => {
      // Hide the video if it fails to load
      if (error) {
        setLoadFailed(true);
      }
    });
    playerRef.current.on('durationchange', ({ duration }) => setVideoDuration(duration));
    playerRef.current.on('timeupdate', ({ seconds }) => setCurrentTime(seconds));
    playerRef.current.on('ended', () => setPlaying(false));
  }, []);

  return (
    <>
      {loadFailed ? (
        ''
      ) : (
        <section className={styles.section}>
          <div className={styles.content}>
            <div
              className={css(
                styles.vimeo,
                playing && styles.playing,
                isScrubbing && styles.scrubbing
              )}
            >
              <div className={styles.container} ref={containerRef} />
              <button
                role="button"
                aria-label="Play/Pause"
                className={styles.overlay}
                onClick={toggleVideo}
              >
                <div
                  className={css(styles.buttonContainer)}
                  style={
                    playing ? { transform: `translateY(${playerBounds.height * 0.5 - 5}px)` } : {}
                  }
                >
                  <div
                    className={css(styles.button)}
                    style={
                      playing
                        ? {
                            transform: `translateX(${
                              playerBounds.width * (percent / 100) - playerBounds.width * 0.5
                            }px) scale(0.333)`,
                          }
                        : {}
                    }
                  >
                    <div className={styles.wrapper}>
                      <div className={css(styles.inner, styles.playButton)}></div>
                    </div>
                  </div>
                </div>
              </button>
              <div className={styles.progressBar}>
                <label
                  id="progress-label"
                  htmlFor="progress"
                  style={
                    playing
                      ? {
                          transform: `translateX(${
                            playerBounds.width * (percent / 100) - playerBounds.width * 0.5
                          }px) scale(0.333)`,
                        }
                      : {}
                  }
                >
                  {currentTime}
                </label>
                <input
                  aria-labelledby="progress-label"
                  name="progress"
                  type="range"
                  min="0"
                  max="100"
                  value={isScrubbing ? undefined : percent}
                  className={styles.progress}
                  onFocus={startScrubbing}
                  onBlur={stopScrubbing}
                  onMouseDown={startScrubbing}
                  onMouseUp={stopScrubbing}
                  onChange={handleScrub}
                  style={{ backgroundPosition: `${-1 * percent}%` }}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlockVimeo;
