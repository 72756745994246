import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { css } from '@oddcommon/utils';

import Block from '@components/Block/Block';
import ScrollMarquee from '@components/ScrollMarquee';
import Meta from '@components/Meta';

import styles from './index.module.scss';
import CTA from '@components/CTA';
import SuggestedCaseStudy from '@components/SuggestedCaseStudy';
import AutoHeadline from '@components/AutoHeadline';

const formatClientList = list => {
  if (list.length === 1) {
    return list[0];
  }
  return list.reduce((prev, current, ind) => {
    if (ind + 1 === list.length) {
      return `${prev} x ${current}`;
    } else {
      return `${prev}, ${current}`;
    }
  });
};

// Number of contributors that trigger adding another column
const CONTRIBUTOR_BREAKPOINTS = [6];

const CaseStudyPage = props => {
  const {
    pageContext: {
      clientList,
      agency,
      lede,
      projectTitle,
      brief,
      role,
      liveLink,
      primaryImage,
      thumbnailImage,
      blocks,
      backgroundColor,
      foregroundColor,
      accentColor,
      contributors,
      suggestion,
    },
  } = props;

  const [loaded, setLoaded] = useState(false);
  const [introHasPlayed, setIntroHasPlayed] = useState(false);
  const { lowBattery, actions } = useContext(GlobalContext);

  useEffect(() => {
    if (loaded) actions.setPageLoaded(true);
  }, [loaded]);

  useEffect(() => {
    if (!loaded && primaryImage.file.contentType.includes('image')) {
      setLoaded(true);
    }
  }, []);

  // Split the contributors into groups if needed
  let contributorGroups = [];
  if (contributors.length >= CONTRIBUTOR_BREAKPOINTS[1]) {
    // 3 Groups
    const groupSize = Math.ceil(contributors.length / 3);
    contributorGroups[0] = contributors.slice(0, groupSize);
    contributorGroups[1] = contributors.slice(groupSize, groupSize * 2);
    contributorGroups[2] = contributors.slice(groupSize * 2);
  } else if (contributors.length >= CONTRIBUTOR_BREAKPOINTS[0]) {
    // 2 Groups
    const groupSize = Math.ceil(contributors.length / 2);
    contributorGroups[0] = contributors.slice(0, groupSize);
    contributorGroups[1] = contributors.slice(groupSize);
  } else {
    // 1 Group
    contributorGroups[0] = contributors;
  }
  const contributorCredits = contributorGroups.map((group, index) => {
    if (!group.length) {
      return '';
    }
    return (
      <div key={`group-${index}`} className={styles.contributorsList}>
        {group.map(contributor => {
          return (
            <dl key={`${contributor.name}-${contributor.role}`} className={styles.contributor}>
              <dt className={styles.contributorName}>{contributor.name}</dt>
              <dd className={styles.contributorRole}>{contributor.role}</dd>
            </dl>
          );
        })}
      </div>
    );
  });
  return (
    <>
      <Meta
        title={`OddCommon | ${projectTitle}`}
        description={lede}
        image={getImage(thumbnailImage)}
      />
      <div
        className={styles.caseStudy}
        // Overrides theme colors if defined
        // If there is an accent color the theme icon is disabled
        style={{
          '--color-background': backgroundColor,
          '--color-foreground': foregroundColor,
          '--color-accent': accentColor,
          '--theme-icon': accentColor ? '""' : undefined,
        }}
      >
        <div className={styles.content}>
          <div className={styles.intro} data-is-dark={true}>
            <div className={css(styles.introMedia, loaded && styles.loaded)}>
              {primaryImage.file.contentType.includes('video') && (
                <>
                  <GatsbyImage
                    className={css(styles.introImage, styles.placeholder)}
                    src={primaryImage.file.url}
                    image={getImage(thumbnailImage)}
                    alt={`${formatClientList(
                      clientList.map(client => client.name)
                    )} - ${projectTitle}`}
                    onLoad={() => {
                      if (!loaded) setLoaded(true);
                    }}
                  />
                  <video
                    className={css(styles.introVideo)}
                    src={primaryImage.file.url}
                    style={{
                      opacity: introHasPlayed ? 1 : 0,
                      visibility: introHasPlayed ? 'visible' : 'hidden',
                    }}
                    loop
                    muted
                    autoPlay
                    playsInline
                    onLoadedMetadata={e => {
                      if (!loaded) setLoaded(true);
                      e.target.play().catch(() => {
                        actions.setLowBattery(true);
                      });
                    }}
                    onCanPlay={e => {
                      if (!loaded) setLoaded(true);
                      e.target.play().catch(() => {
                        actions.setLowBattery(true);
                      });
                    }}
                    onPlay={() => {
                      setIntroHasPlayed(true);
                    }}
                  ></video>
                </>
              )}
              {primaryImage.file.contentType.includes('image') && (
                <GatsbyImage
                  className={css(styles.introImage)}
                  src={primaryImage.file.url}
                  image={getImage(primaryImage)}
                  alt={`${formatClientList(
                    clientList.map(client => client.name)
                  )} - ${projectTitle}`}
                  onLoad={() => {
                    if (!loaded) setLoaded(true);
                  }}
                />
              )}
            </div>
          </div>
          <header className={styles.header}>
            {/* <AutoHeadline className={styles.lede}>{lede}</AutoHeadline> */}
            <h1 className={styles.lede}>{lede}</h1>
            <div>
              <div className={styles.sideBySide}>
                <h2>Client</h2>
                <p>{formatClientList(clientList.map(client => client.name))}</p>
              </div>
              {agency && (
                <div className={styles.sideBySide}>
                  <h2>Agency</h2>
                  <p>{agency.name}</p>
                </div>
              )}
              {brief && (
                <div className={styles.sideBySide}>
                  <h2>About</h2>
                  <article dangerouslySetInnerHTML={{ __html: brief.childMarkdownRemark.html }} />
                </div>
              )}
              {role && (
                <div className={styles.sideBySide}>
                  <h2>Role</h2>
                  <ul className={styles.roleList}>
                    {role.map(roleType => (
                      <li key={roleType}>{roleType}</li>
                    ))}
                  </ul>
                </div>
              )}
              {liveLink && (
                <div className={styles.sideBySide}>
                  <span />
                  <p>
                    <CTA className={styles.liveLink} href={liveLink}>
                      See it live
                    </CTA>
                  </p>
                </div>
              )}
            </div>
          </header>
          <div className={styles.sectionBlocks}>
            {blocks.map(block => {
              const type = block.__typename;

              const { id } = block;
              if (Block[type]) {
                const BlockComponent = Block[type];
                delete block.sys;
                return <BlockComponent key={id} {...block} />;
              } else {
                return null;
              }
            })}
          </div>
          {contributors && (
            <section
              className={css(
                styles.sectionContributors,
                styles[`groups_${contributorCredits.length}`]
              )}
            >
              <div className={styles.contributorsMarquee}>
                <ScrollMarquee
                  items={[
                    'Credits',
                    'The Dream Team',
                    'Credits',
                    'The Dream Team',
                    'Credits',
                    'The Dream Team',
                  ]}
                  direction="left"
                  speed={100}
                />
                <ScrollMarquee
                  items={[
                    'Credits',
                    'The Dream Team',
                    'Credits',
                    'The Dream Team',
                    'Credits',
                    'The Dream Team',
                  ]}
                  direction="right"
                  speed={100}
                />
              </div>
              <div className={styles.sideBySide}>
                <h2>Please Clap</h2>
                {contributorCredits}
              </div>
            </section>
          )}
        </div>
      </div>
      {suggestion && <SuggestedCaseStudy {...suggestion} />}
    </>
  );
};
export default CaseStudyPage;
